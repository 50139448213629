import React from 'react';
import { ConsultantProgramViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { ConsultantProgramView } from '../views';

const ConsultantProgram: React.FunctionComponent<TemplateProps<
  ConsultantProgramViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <ConsultantProgramView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
    />
  );
};

export default ConsultantProgram;
